

.page {
  width: 100%;
  height: 100%;
  position: absolute;
}


::selection {
  background: #ffd700; /* Background color for selected text */
  color: #333; /* Text color for selected text */
}
.link-name-tag {
  color: #ffd700;
  opacity: 1;
  position: absolute;
  margin: auto;
  left: 110px;
  font-size: 45px;
  font-family: 'Coolvetica';
  text-decoration: none;
  bottom: auto;
  font-weight: 300;
  top: 25px;
  
  &:hover .tooltip{
    opacity: 1;
  
  }
}

.link-name-tag-footer{
  color: #ffd700;


}
.flat-button {
  color: #ffd700;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 30px; /* Adjust padding for clickable area */
  border: 1px solid #ffd700;
  margin-top: 30px;
  margin-right: 30px;
  float: right;
  animation: fadeInAnimation 1s 1.8s backwards;
  white-space: nowrap;
  pointer-events: auto;

  &:hover {
    background: #ffd700;
    color: #333;
    
  }
}


.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore', cursive;
}

.tooltip {
  position: absolute;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-size: 14px;
  pointer-events: auto; /* Ensure the tooltip is clickable */

  .tooltip-box {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 14px;
  }
}

.link-name-tag {
  cursor: pointer;
  user-select: none;

  &:hover .tooltip {
    opacity: 1;
    top: -40px;
  }
}




.bottom-tag-html {
  position: fixed; /* Fixed position */
  left: 50%; /* Move to the horizontal center */
  transform: translateX(-50%); /* Adjust to center properly */
  bottom: 0; /* Stick to the bottom of the screen */
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 500px;
  position: absolute;
  opacity: 0;
  top: 10%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.contact-page,
.container.about-page,
.container.portfolio-page {
  
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 13px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }
  .link-name-tag .tooltip {
    top: 30px;
    left: 10px;
  }

  .link-name-tag-footer{
    position: initial;
  }
  .flat-button{
    display: none;
  }
  .container {
    position: initial;
    height: auto;
    min-height: auto;

    &.contact-page,
    &.about-page,
    &.portfolio-page {
      .text-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }

    &.portfolio-page {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      h1.page-title {
        margin-left: 20px;
        margin-top: 20px;
      }

      .image-box {
        height: 200px;
        max-width: calc(50% - 10px);
      }
    }
  }

  .stage-cube-cont {
    position: initial;
    width: 100%;
    height: 0%;
    overflow: visible;
  }
}
@media screen and (max-width: 480px){
  .container.contact-page h1{
    font-size: 30px;
  }
  .home-page h1{
    font-size: 30px;
  }
.link-name-tag{
  margin-top: 10px;
  
  font-size: 30px;
}
}
