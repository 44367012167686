.home-page {
  .text-zone , canvas.vanta-canvas{
    position: relative;
    left: 10%;
    top: 40%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  ::selection {
    background: #ffd700; /* Background color for selected text */
    color: #333; /* Text color for selected text */
  }
  
  
  
  h1 {
    cursor: pointer;
    color: #fff;
    font-size: 50px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: 2px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    img {
      margin-left: 20px;
      opacity: 0;
      width: 32px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: "Space Grotesk", sans-serif;
    overflow: hidden; /* Changed from 'flex' to 'hidden' as 'flex' is not a valid value */
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    box-sizing: border-box; /* Include padding and borders in the element's total width and height */
  }
  
  .flat-button:hover {
    background: #ffd700;
    color: #333;
  }
  
}



@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1:before,
  .home-page h1:after {
    display: absolute;
    z-index: 1;
  }

  .home-page .text-zone {
    position: initial;
    width: 100%;
    transform: none;
    padding: 20px;
    margin: 30px auto 0 auto;
    top:40px;
    box-sizing: border-box;
 
  }

  .home-page .flat-button {
    float: none;
    display: block;
    margin: 20px auto; /* shorthand for 20px top/bottom, auto left/right */
    width: 100px; /* adjust the width or set to auto to accommodate the content */
    min-width: 200px; /* optional: ensure button is not smaller than a certain width */
    padding: 10px 18px; /* ensure there's enough padding */
    box-sizing: border-box; /* include padding and border in the width and height */
    /* other styles */
  }
  

  .logo-container {
    position: relative;
    width: 100px;
    height: auto;
    top: 40px;
    right: 0;
    box-sizing: border-box;
    margin: auto;
    left: 0;
  }

  .logo-container svg {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
  }
}

@media screen and (max-width: 480px){

  .home-page h1{
    font-size: 40px;
}
}