
.project-heading {
  font-family: "Space Grotesk", sans-serif;
  text-align: center;
  font-size: 80px; /* Moderate size for simplicity */
  margin-bottom: 30px; /* Uniform spacing for a clean look */
  color: white;
  font-weight: 500;
  padding-top: 10px;
  transition: font-size 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}

.project-heading:hover {
  font-size: 85px; /* Slightly increase font size */
  color: #a2d5f2; /* Change color to a light blue for a cool effect */
  cursor: pointer; /* Change cursor to indicate clickable */
}



.project-section {
    margin-top: 100px;
    position: relative; /* Changed to relative to position within its normal flow */
    display: flex; /* Use flexbox to center items */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* centers items on the cross-axis (horizontally) */
    justify-content: center; /* centers items on the main-axis (vertically) */
    padding-top: 100px; /* You may adjust this as needed */
    padding-bottom: 100px; /* You may adjust this as needed */
    background-image: var(--section-background-color);
    height: 100vh; /* Set the height to full viewport height to ensure vertical centering */
    width: 100%; /* Set the width to full container width */
  }
  
  .title_projects {
    text-align: center;
    font-family: "Space Grotesk", sans-serif;
    cursor: pointer;
    font-size: 12px;
    position: relative; /* Needed for absolute positioning of pseudo-elements */
    padding-bottom: 5px; /* Provide space for the underline */
  }
  
  .title_projects::after {
    content: '';
    position: absolute;
    width: 0; /* Initially no width */
    height: 1px; /* Or the thickness you want for your underline */
    background: currentColor; /* Use the current text color for the underline */
    bottom: 0; /* Align at the bottom of the text */
    transition: width 0.3s ease; /* Smooth transition for the width */
    left: 50%; /* Start in the center */
    transform: translateX(-50%); /* Center the pseudo-element */
  }
  
  .title_projects:hover::after {
    width: 100%; /* Full width on hover */
    left: 0; /* Reset left position */
    transform: translateX(0%); /* Reset transform */
  }
  
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; // This makes sure the parent is the full height of the viewport
  }
  
  .project-card {
    padding-top: 50px ;
    padding-bottom: 50px ;
    padding-left: 90px ;
    padding-right: 25px ;
    height: auto ;
  }
  
  .project-card-view {
    box-shadow: 0 4px 5px 3px #0a3946 ;
    color: white ;
    background-color: transparent ;
    opacity: 0.9 ;
    transition: all 0.5s ease 0s ;
    height: 100% ;
    cursor: pointer;
  }
  .project-card-view:hover {
    transform: scale(1.02) ;
    overflow: hidden ;
    box-shadow: 0 4px 5px 3px #2e5c7c ;
  }
  
  .blog-card {
    padding-top: 50px  ;
    padding-bottom: 50px ;
    padding-left: 25px ;
    padding-right: 25px ;
    height: auto ;
  }
  
  .blog-card-view {
    background-color: transparent ;
    box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) ;
    color: white ;
    transition: all 0.5s ease 0s ;
    height: 100% ;
  }
  
  .blog-link {
    color: white ;
    text-decoration: none ;
  }
  
  .blog-link:hover {
    cursor: pointer ;
  }
  
  .blog-card-view:hover {
    transform: scale(1.02) ;
    overflow: hidden ;
    box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) ;
  }
  
  .card-img-top {
    padding: 20px ;
    opacity: 0.8 ;
    border-radius: 10px ;
  }
  
  .blog-img {
    padding: 0px ;
    opacity: 0.8 ;
    border-radius: 0px ;
  }
  
  .btn-primary {
    color: #fff ;
    background-color: #e0c116 ;
    border-color: #e0c116 ;
  }
  
  .btn-primary:hover {
    color: #fff ;
    background-color: #2e5c7c ;
    border-color: #2e5c7c ;
  }
  .btn:focus {
    outline: none ;
    box-shadow: none ;
  }

  .footer {
    padding: 100px;
    text-align: center;
    font-family: "Space Grotesk",sans-serif;
    border-radius: 8px;
    margin-top: 700px;
    float: bottom;
    font-size: 18px;
    color: #fff;
  }
  .building-text{
    font-size: 35px;
    padding-bottom: 40px;
    margin-bottom: 0.5 rem;
  }
  .bottom-contact{
      color: #ffd700;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: "Space Grotesk",sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #ffd700;
      margin-top: 20px;
     
     
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;
  
      &:hover {
        background: #ffd700;
        text-decoration: none;
        color: #333;
      }
  }
  .copy{
    padding-top: 60px;
    font-size:small;
  }
  .copyright{

    color:grey;
  }

/* Base styles here */

/* Media query for tablets */
@media (max-width: 1200px) {
  .footer{
    margin-top: 100px;
  }

  .project-card {
    padding-top: 50px ;
    padding-bottom: 50px ;
    padding-left: 25px ;
    padding-right: 25px ;
    height: auto ;
  }

}
@media (max-width: 768px) {
  .footer{
    margin-top: 50px;
  }
  .project-section,
  .loader-container {
    height: auto; /* Adjust height for smaller screens */
    padding-top: 20px;
    padding-bottom: 15px;
  }

  .project-card,
  .blog-card,
  .project-card-view,
  .blog-card-view {
    padding: 25px 10px; /* Less padding on smaller screens */
  }

  .card-img-top,
  .blog-img {
    padding: 15px; /* Smaller padding for images */
  }

  .project-heading {
    font-size: 30px; /* Smaller font size for headings */
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {

  .footer{
    margin-top: 50px;
    font-size: 5px;
  }
  .building-text{
    font-size: 20px;
  }
  .project-section {
    margin-top: 20px;
  }

  .project-card,
  .blog-card,
  .project-card-view,
  .blog-card-view {
    box-shadow: none; /* Remove box-shadow for a flatter design on mobile */
  }

  .project-card-view:hover,
  .blog-card-view:hover {
    transform: none; /* Disable hover effects on mobile */
  }

  .btn-primary {
    background-color: #0a3946; /* Consistent button color for mobile */
  }

  .btn-primary:hover {
    background-color: #083c4a; /* Consistent hover color */
  }
}
