/* index.scss */
/* Apply styles for all browsers */
body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of horizontal scrollbar, if necessary */
}

body::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.5); /* Color of the scroll thumb */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for hover effects */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0,0,0,0.7);
}
body::-webkit-scrollbar {
  width: 0; /* Initially set to 0 to hide by default */
}

body:hover::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar {
  width: var(--scrollbar-width, 0);
}


html, body {
  overflow-y: auto;  // This allows vertical scrolling when needed
  height: 100%;     // Ensuring that the height covers the entire viewport
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #1a1a1a; // Or any desired background color
  overflow-x: hidden;
}


.about {
  font-family: 'Arial', sans-serif;
  background-color: rgb(18, 30, 39);
  margin-top: 50px;
  color: #333;
  overflow-y: auto; // Added just in case this container is causing overflow issues
  overflow-x: hidden;
 
  .arrow {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 50%;
    background: transparent;
    color: #fff;
    border: none;
    cursor: pointer; 
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: background 0.5s; /* Adjust the time for how fast you want the fill effect */
    background-size: 100% 0;
    background-position: 0 100%;
}

.arrow:hover {
    background: linear-gradient(to top, #add8e6, #add8e6) no-repeat; /* #555 is the color you want the circle to fill with */
    background-size: 100% 100%;
    
}


 

  .container {
    padding: 40px 60px;
    max-width: 95%;
    margin-top:  40px;
    margin-left: 50px;
   
    background-color:  rgb(18, 30, 39);
    border-radius: 8px;
    box-sizing: border-box;
    
    $primary-color: rgba(8, 253, 216, 0.1); // Pale turquoise with low opacity
    $secondary-color: #0a3946; // A darker teal/turquoise for contrast
    $hover-color: #2e5c7c; // A warm, light orange for a soft but visible hover effect
  }
  .skills-section {

    margin-top: 40px;
    font-family: "Space Grotesk",sans-serif;
    padding: 30px;
     
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    
    h2 {
      color: #fff; // White for contrast
      margin-bottom: 25px;
      text-transform: uppercase; // Added uppercase for a bolder look
      letter-spacing: 1.5px; // Spacing between letters for style
      font-size: 24px;

    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      color: white;
      margin-right: 5px;
      
      li {
        padding: 8px 15px;
        background-color: #0a3946;
        border-radius: 4px;
        transition: background-color 0.3s;

        &:hover {
          background-color: #2e5c7c;
          cursor: pointer;
          
        }
      }
    }
  }

  .footer {
    padding: 50px;
    text-align: center;
    font-family: "Space Grotesk",sans-serif;
    border-radius: 8px;
    margin-top: 40px;
    float: bottom;
    font-size: 18px;
    color: #fff;
  }
  .building-text{
    font-size: 35px;
    padding-bottom: 40px;
    margin-bottom: 0.5 rem;
  }
  .bottom-contact{
      color: #ffd700;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: "Space Grotesk",sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #ffd700;
      margin-top: 20px;
     
     
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;
  
      &:hover {
        background: #ffd700;
        color: #333;
      }
  }
  .copy{
    padding-top: 60px;
    font-size:small;
  }
  .copyright{

    color:grey;
  }


  .flat-button-top {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: "Space Grotesk",sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-right: 25px;
   
    float: right;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }

  .about-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-family: "Space Grotesk",sans-serif;
    gap: 30px;

    @media (max-width: 1100px) {
      
      flex-direction: column;
            width: 90%;
            margin: 0 auto;
            overflow-x: hidden;

    }
    @media (max-width: 480px) {
      
      flex-direction: column;
            width: 100%;
            margin: 0 auto;
            overflow-x: hidden;

    }
  }

  .about-images {
    flex: 0.4;
    position: relative;

    img {
      max-width: 95%;
      border-radius: 8px;
      transition: all 0.3s;
    }

    img:hover {
      transform: scale(1.05);
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
  }

  .about-details {
    flex: 0.9;
    margin-top: -30px;
    margin-bottom: 40px;
    font-family: "Space Grotesk",sans-serif;

    .code-runner{
      font-family: "Space Grotesk 600",sans-serif;
      font-size: 38px;

    }
    .hover-image-link img {
      display: none;         /* Hide the image by default */
      position: absolute;    /* Absolute position to display it above other elements */
      width: 100px;          /* Width of the image when displayed. Adjust as needed */
      border: 1px solid #ccc; /* Optional: Add a border to the image */
      padding: 5px;          /* Optional: Add some padding around the image */
  }
  
  .hover-image-link:hover img {
      display: block;        /* Display the image on hover */
  }
  
    h1 {
      font-size: 16px;
      font-family: "Space Grotesk",sans-serif;
      color: grey;
      margin-bottom: 14px;
      position: relative;  /* Updated to relative */
      display: inline-block;
      cursor: pointer;
   }
   
   h1:after {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      background:  #FFFF9E;
      bottom: 0;
      left: 50%;
      transition: width 0.3s, left 0.3s;  /* Smooth transition effect */
   }
   
   h1:hover:after {
      width: 100%;  /* Full width on hover */
      left: 0;      /* Start from the leftmost side */
      cursor: pointer;
   }

   .download {
    color: #ffd700;
    font-family: "Space Grotesk", sans-serif;
    font-size: 16px;
    margin-top: 10px;
    text-decoration: none;
    position: relative;
    transition: all 0.3s;
}

.download::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: #ffd700;
    transition: all 0.3s;
}

.download:hover::before {
    width: 100%;
    left: 0;
}


   

p {
    
    font-family: "Space Grotesk",sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: white;
    background: none;
}

/* Hello World Code styling */
.hello-world {
  border-right: 2px solid #000;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Courier New', monospace;  // Gives it a coding feel
  background-color: rgb(18, 30, 39);  // Change this to your desired background color
  color: #333;  // Change this to your desired font color
  font-size: 18px;  // Adjust the font size as needed
  padding: 2px 4px;
  border-radius: 2px;
  display: inline-block;
  animation: typing 2s steps(25, end), blink 1s step-end infinite;  // Adjust animation timing if needed
}




  }

  $primary-color: rgba(8, 253, 216, 0.1); // Pale turquoise with low opacity
  $secondary-color: #0a3946; // A darker teal/turquoise for contrast
  $hover-color: #2e5c7c; // A warm, light orange for a soft but visible hover effect
  
    
    .experience-section,
    .education-section {
      margin-top: 40px;
      padding: 30px;
      
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      
      font-family: "Space Grotesk",sans-serif;
      
      h2 {
        font-size: 26px; // Made slightly bigger for boldness
        color: #fff; // White for contrast
        margin-bottom: 25px;
        text-transform: uppercase; // Added uppercase for a bolder look
        letter-spacing: 1.5px; // Spacing between letters for style
      }
    
      .experience-item,
      .education-item {
        margin-bottom: 25px;
        padding: 20px;
        background-color: $secondary-color; // Slightly transparent background
        border-radius: 8px;
        transition: background-color 0.3s;
    
        &:hover {
          background-color: $hover-color; // Slight color change on hover
          cursor: pointer;
        }
    
        h3 {
          font-size: 22px; // Made slightly bigger for boldness
          margin-bottom: 15px;
          color: #fff; // White for contrast
        }
    
        p {
          font-size: 18px;
          color: rgba(255, 255, 255, 0.8); // Slightly transparent white for content
        }
      }
    }
  }
    

@media screen and (max-width: 1200px) {
  .about{
    max-width: 92%;
  }
  .arrow{
    display: none;
  }
  .flat-button-top{
    display: none;
  }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  50% { border-color: transparent }
}

.hello-world {
  border-right: 2px solid #000;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Courier New', monospace;  /* Gives it a coding feel */
  background-color: #eee;  /* Light grey background */
  padding: 2px 4px;
  border-radius: 2px;
  display: inline-block;
  animation: typing 3s steps(30, end), blink 1s step-end infinite;
}

.icon {
  margin: 0 4px;
}

@media (max-width: 480px) {
  .page .container {
    margin-left: 10px;
    margin: 0;
    width: 100%;
    padding: 0; // Ensure the padding doesn't affect the full width
    box-sizing: content-box; // Ensure padding is outside the width calculation
  
  }
  .about .container{
    max-width: 100%;
  }
  .about {
    width: 100%;
    max-width: 100%;
    margin: 0 0 0 0 ; // Horizontally center .about in .page
    padding: 0; // Remove padding that might affect the full width
  }
  
  .about-content {
    
    flex-direction: column;
    align-items: center; // Center children horizontally in the column
    width: 100%; // Take full width of .about
    margin: 0 0 0 0; // Remove any default margins
  }

 
  .about-details {
    text-align: center; // Center-align the text for smaller screens

    width: 95%; // Full width within .about-content
    margin: 0; // Remove any default margins
    padding: 0; // Reset padding to ensure full width
  }
  .about-images{
    
    width: 90%; // Full width within .about-content
    margin-left: 15px; // Remove any default margins
    padding: 0; // Reset padding to ensure full width
  }
  
  .about-images img {
    width: 100%; // Ensure the image takes full width of its container
    max-width: 100%;
    height: auto; // Maintain aspect ratio
    border-radius: 0; // Remove border-radius if desired
  }



  // Optionally reset box-sizing for all elements if it's not already the default

}
